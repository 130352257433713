import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from 'marketplace/components/Authentication/icons/FacebookIcon';
import {
  facebookBlue,
  facebookBlueHover,
} from 'shared/constants/styleConstants';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: facebookBlue,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    color: '#fff',
    cursor: 'pointer',
    width: '100%',
    minWidth: '45px',
    textTransform: 'none',
    '&:focus': {
      backgroundColor: facebookBlueHover,
      outline: 0,
    },
    '&:hover': {
      backgroundColor: facebookBlueHover,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  connectButtonContainerFullWidth: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
  connectButtonContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    width: '100%',
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      maxWidth: '250px',
    },
  },
  iconContainer: {
    justifyContent: 'left',
  },
  textContainer: {
    textAlign: 'center',
    flex: '1',
  },
});

const FacebookButtonV2 = ({ children, fullWidth, classes, ...baseProps }) => (
  <div
    className={
      fullWidth
        ? classes.connectButtonContainerFullWidth
        : classes.connectButtonContainer
    }
  >
    <Button className={classes.root} variant="contained" {...baseProps}>
      {
        <div className={classes.iconContainer}>
          <FacebookIcon />
        </div>
      }
      <div className={classes.textContainer}>
        <Typography variant="button">{children}</Typography>
      </div>
    </Button>
  </div>
);

FacebookButtonV2.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  fullWidth: PropTypes.bool,
};

FacebookButtonV2.defaultProps = {
  fullWidth: false,
};

export default withStyles(styles)(FacebookButtonV2);
