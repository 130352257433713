/*
 * Login.jsx
 *
 * Point-of-reference: Prescott Rynewicz
 * Purpose: Allow users to login
 * Location: Header (when not logged in) --> Login
 * Interacts-with: N/A
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import MySnackbarContentWrapper from 'shared/components/SnackBar/SnackBarContentWrapper';
import { EMAIL_LOGIN_V2 } from 'shared/constants/gql-constants';
import SHA256 from 'crypto-js/sha256';
import cx from 'classnames';
import OAuthDetectedDialog from 'marketplace/components/Authentication/AuthenticationDialog/OAuthDetectedDialog';
import withClient from 'shared/components/ApolloClient/withClient';
import PrimaryButton from 'shared/components/Buttons/PrimaryButton/PrimaryButton';
import GoogleButton from 'shared/components/Buttons/GoogleButton/GoogleButton';
import FacebookButton from 'shared/components/Buttons/FacebookButton/FacebookButton';
import { errorAlert } from 'shared/utilities/alerts';
import { isEmpty, getErrorMessage } from 'shared/utilities/utils';
import Link from 'shared/components/Link/Link';
import GoogleIcon from '../icons/GoogleIcon';
import FacebookIcon from '../icons/FacebookIcon';
import withStyles from './LoginStyles';

class Login extends Component {
  static propTypes = {
    client: PropTypes.object.isRequired, // eslint-disable-line
    classes: PropTypes.object.isRequired, // eslint-disable-line
    redirect: PropTypes.string,
  };

  static defaultProps = {
    redirect: '',
  };

  constructor() {
    super();

    this.state = {
      loggingInWithEmail: false,
      email: '',
      password: '',
      emptyEmailSnackbarOpen: false,
      emptyPasswordSnackbarOpen: false,
      loading: false,

      // Error state for catching case when user should OAuth Authenticate
      detectOAuthLogin: false,
      detectOAuthLoginContext: null,
    };
  }

  getGoogleLoginUri = () => {
    const { redirect } = this.props;
    if (isEmpty(redirect)) {
      return '/login/google';
    }
    return `/login/google/redirect?redirectUri=${encodeURI(redirect)}`;
  };

  getFacebookLoginUri = () => {
    const { redirect } = this.props;
    if (isEmpty(redirect)) {
      return '/login/facebook';
    }
    return `/login/facebook/redirect?redirectUri=${encodeURI(redirect)}`;
  };

  handleValueChange = field => ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ [field]: value });
  };

  handleOpenEmailEmptySnackbar = () => {
    this.setState({ emptyEmailSnackbarOpen: true });
  };

  closeEmailEmptySnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ emptyEmailSnackbarOpen: false });
  };

  handleOpenPasswordEmptySnackbar = () => {
    this.setState({ emptyPasswordSnackbarOpen: true });
  };

  closePasswordEmptySnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ emptyPasswordSnackbarOpen: false });
  };

  loginEmail = async event => {
    event.preventDefault(); // This prevents React from automatically reloading the page due to form submission
    this.setState({ loading: true });

    const { email, password } = this.state;
    const pass = SHA256(password).toString();

    if (isEmpty(email)) {
      this.setState({ loading: false });
      this.setState({ emptyEmailSnackbarOpen: true });
      return;
    }

    if (isEmpty(password)) {
      this.setState({ loading: false });
      this.setState({ emptyPasswordSnackbarOpen: true });
      return;
    }

    try {
      const { data } = await this.props.client.mutate({
        mutation: EMAIL_LOGIN_V2,
        variables: {
          user: email.trim().toLowerCase(),
          pass,
        },
      });

      const { token } = data.emailLoginV2;

      const body = { apiToken: token, username: email, password };

      await fetch('/login', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
      });

      window.analytics.track('Logged In'); // Placing the login tracking event for email here.

      if (isEmpty(this.props.redirect)) {
        window.location.href = `/`;
      } else {
        window.location.href = this.props.redirect;
      }
    } catch (err) {
      this.setState({ loading: false });
      this.handleError(err);
    }
  };

  handleChange = field => e => {
    this.setState({
      [field]: e.target.value,
    });
  };

  handleError = error => {
    const errorMessage = getErrorMessage(error);

    if (errorMessage === 'Please log in with Google') {
      this.setState({
        detectOAuthLogin: true,
        detectOAuthLoginContext: 'google',
      });
      return;
    }

    if (errorMessage === 'Please log in with Facebook') {
      this.setState({
        detectOAuthLogin: true,
        detectOAuthLoginContext: 'facebook',
      });
      return;
    }

    errorAlert('Could not Login', errorMessage);
    this.setState({
      password: '',
    });
  };

  render = () => {
    const { handleChange, loginEmail } = this;
    const {
      loggingInWithEmail,
      email,
      password,
      emptyEmailSnackbarOpen,
      emptyPasswordSnackbarOpen,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography className={cx(classes.title, classes.content)} variant="h4">
          Log in to Tutorfly
        </Typography>
        <div className={classes.content}>
          {!loggingInWithEmail && (
            <Fragment>
              <form onSubmit={loginEmail}>
                <TextField
                  id="username"
                  name="username"
                  className={classes.field}
                  fullWidth
                  label="EMAIL ADDRESS"
                  onChange={handleChange('email')}
                  type="email"
                  value={email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="password"
                  type="password"
                  name="password"
                  fullWidth
                  label="PASSWORD"
                  onChange={handleChange('password')}
                  value={password}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {!this.state.loading && (
                  <PrimaryButton type="submit">Log In</PrimaryButton>
                )}

                {this.state.loading && (
                  <CircularProgress className={classes.loadingIcon} />
                )}
              </form>

              <Typography className={classes.footerLink}>
                <Link to="/forgot-password">Forgot your password?</Link>
              </Typography>
              <strong className={classes.lineThrough}>OR</strong>
              <GoogleButton href={this.getGoogleLoginUri()}>
                <div className={classes.iconContainer}>
                  <GoogleIcon />
                </div>
                <div className={classes.textContainer}>
                  <Typography variant="button">Log in with Google</Typography>
                </div>
              </GoogleButton>
              <FacebookButton href={this.getFacebookLoginUri()}>
                <div className={classes.iconContainer}>
                  <FacebookIcon />
                </div>
                <div className={classes.textContainer}>
                  <Typography variant="button">Log in with Facebook</Typography>
                </div>
              </FacebookButton>
              <Typography className={classes.footerText}>
                {'By logging in, you agree to the Tutorfly '}
                <Link to="/terms" target="_blank" rel="noopener noreferrer">
                  Terms
                </Link>
                {' and '}
                <Link to="/privacy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Link>
              </Typography>
              <hr />
            </Fragment>
          )}
        </div>
        <Typography className={classes.footerText}>
          {"Don't have an account yet? "}
          <Link to="/signup">Sign Up</Link>
        </Typography>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={emptyEmailSnackbarOpen}
          autoHideDuration={3000}
          onClose={this.closeEmailEmptySnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.closeEmailEmptySnackbar}
            variant="error"
            message="Please enter in an email to log in."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={emptyPasswordSnackbarOpen}
          autoHideDuration={3000}
          onClose={this.closePasswordEmptySnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.closePasswordEmptySnackbar}
            variant="error"
            message="Please enter a password to log in."
          />
        </Snackbar>

        <OAuthDetectedDialog
          isOpen={this.state.detectOAuthLogin}
          context={this.state.detectOAuthLoginContext}
        />
      </div>
    );
  };
}

export default compose(withClient, withStyles)(Login);
