/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/**
 * Primary Button
 *
 * Point of Reference: Ryan Stenberg/Anupya
 * Purpose: Button used as the base for all call to actions or to direct users through our desired flow
 * Location: N/A
 * Interacts-with: N/A
 */

// When overwriting styling of the primary button, use !important on the css class styling to ensure that it works

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import withStyles from './PrimaryButtonStyles';

const PrimaryButton = ({
  children,
  classes,
  isFacebook,
  isGoogle,
  small,
  style,
  className,
  classNameOverride,
  ...baseProps
}) => (
  <Button
    className={
      classNameOverride
        ? classNameOverride
        : className ? cx(className, classes.root) : classes.root
    }
    // style={style}
    variant="contained"
    {...baseProps}
  >
    {children}
  </Button>
);

PrimaryButton.propTypes = {
  children: PropTypes.node,
  isFacebook: PropTypes.bool,
  isGoogle: PropTypes.bool,
  small: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.object,
  classNameOverride: PropTypes.object,
};

PrimaryButton.defaultProps = {
  children: null,
  isFacebook: false,
  isGoogle: false,
  small: false,
  style: {},
  className: null,
  classNameOverride: null,
};

export default withStyles(PrimaryButton);
