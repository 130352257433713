/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { PropTypes } from 'prop-types';
import { compose } from 'redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import GoogleButtonV2 from 'shared/components/Buttons/GoogleButtonV2';
import FacebookButtonV2 from 'shared/components/Buttons/FacebookButtonV2';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import withClient from 'shared/components/ApolloClient/withClient';
import withStyles from './AuthenticationDialogStyles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function OAuthDetectedDialog(props) {
  const [open, setOpen] = React.useState(props.isOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  React.useEffect(
    () => {
      setOpen(props.isOpen);
    },
    [props.isOpen],
  );

  const handleClose = () => {
    setOpen(false);
  };

  const loginForm = () => (
    <div>
      <div style={{ width: '100%' }}>
        <Box display="flex" className={props.classes.closeDialogContainer}>
          <Box flexGrow={1}>
            {props.context === 'google' && (
              <Typography
                variant="h4"
                className={props.classes.loginOauthHeader}
              >
                Log in with Google
              </Typography>
            )}
            {props.context === 'facebook' && (
              <Typography
                variant="h4"
                className={props.classes.loginOauthHeader}
              >
                Log in with Facebook
              </Typography>
            )}
            {props.context === 'default' && (
              <Typography
                variant="h4"
                className={props.classes.loginOauthHeader}
              >
                Try Logging with Facebook or Google
              </Typography>
            )}
          </Box>
          <Box>
            <IconButton
              aria-label="Close"
              className={props.classes.closeButtonRightAlign}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </div>

      <DialogContent>
        {props.context === 'google' && (
          <div className={props.classes.titleContainer}>
            <Typography variant="body1">
              {
                'Looks like you created your account on Tutorfly with Google. Please click the button below to continue logging in with Google.'
              }
            </Typography>
          </div>
        )}
        {props.context === 'facebook' && (
          <div className={props.classes.titleContainer}>
            <Typography variant="body1">
              {
                'Looks like you created your account on Tutorfly with Facebook. Please click the button below to continue logging in with Facebook.'
              }
            </Typography>
          </div>
        )}
        {props.context === 'default' && (
          <div className={props.classes.titleContainer}>
            <Typography variant="body1">
              {
                'Please try to log into your account with Facebook or Google. Contact the Tutorfly team if you still cannot log in.'
              }
            </Typography>
          </div>
        )}

        {props.context === 'google' && (
          <GoogleButtonV2 fullWidth href="/login/google">
            Log in with Google
          </GoogleButtonV2>
        )}
        {props.context === 'facebook' && (
          <FacebookButtonV2 fullWidth href="/login/facebook">
            Log in with Facebook
          </FacebookButtonV2>
        )}
        {props.context === 'default' && (
          <div>
            <GoogleButtonV2 fullWidth href="/login/google">
              Log in with Google
            </GoogleButtonV2>
            <FacebookButtonV2 fullWidth href="/login/facebook">
              Log in with Facebook
            </FacebookButtonV2>
          </div>
        )}
      </DialogContent>
    </div>
  );

  return (
    <div>
      <Dialog
        open={open}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="authentication-dialog"
      >
        {loginForm()}
      </Dialog>
    </div>
  );
}

OAuthDetectedDialog.propTypes = {
  classes: PropTypes.shape.isRequired,
  isOpen: PropTypes.bool,
  context: PropTypes.string,
};

OAuthDetectedDialog.defaultProps = {
  isOpen: false,
  context: 'default',
};

export default compose(withClient, withStyles)(OAuthDetectedDialog);
