import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  buttonText: {
    marginLeft: theme.spacing(1),
  },
  container: {
    margin: '0px auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    maxWidth: '400px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 239px)',
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      padding: theme.spacing(6),
      minHeight: 'calc(100vh - 239px)',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 'calc(100vh - 270px)',
    },
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  loadingIcon: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  emailIcon: {
    margin: '0',
    marginRight: theme.spacing(1),
    padding: '0',
  },
  facebookIcon: {
    height: '30px',
    marginRight: theme.spacing(1),
    width: '23px',
  },
  footerLink: {
    fontSize: '16px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-1),
    fontFamily: 'Raleway',
    fontWeight: 300,
  },
  footerText: {
    margin: theme.spacing(2, 0),
    fontFamily: 'Raleway',
    fontWeight: 300,
  },
  googleIcon: {
    height: '30px',
    marginRight: theme.spacing(1),
    width: '30px',
  },
  iconContainer: {
    justifyContent: 'left',
  },
  textContainer: {
    flex: '1',
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    textAlign: 'left',
  },
  field: {
    paddingBottom: theme.spacing(2),
  },
  text: {
    fontFamily: 'Raleway',
  },
  lineThrough: {
    position: 'relative',
    zIndex: 1,
    display: 'block',
    marginBottom: '15px',
    width: '100%',
    color: '#757575',
    textAlign: 'center',
    fontSize: '80%',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: '-1',
      marginTop: '-5px',
      marginLeft: '-20px',
      width: '40px',
      height: '10px',
      backgroundColor: '#fff',
      content: '""',
    },
    '&:after': {
      position: 'absolute',
      top: '49%',
      zIndex: '-2',
      display: 'block',
      width: '100%',
      borderBottom: '1px solid #ddd',
      content: '""',
    },
  },
});

export default withStyles(styles);
