import { withStyles } from '@material-ui/core/styles';
import { buttonBlue } from 'shared/constants/styleConstants';

const styles = theme => ({
  closeButton: {
    color: '#333',
    left: theme.spacing(0),
    top: theme.spacing(0),
    padding: theme.spacing(1, 1, 1, 1),
    margin: theme.spacing(2, 0, 0, 2),
  },
  closeButtonRightAlign: {
    color: '#333',
    right: theme.spacing(0),
    top: theme.spacing(0),
    padding: theme.spacing(1, 1, 1, 1),
    margin: theme.spacing(0, 0, 0, 0),
  },
  closeDialogContainer: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  dropDownIcon: {
    color: '#333',
    margin: theme.spacing(0, 1, 0, 0),
  },
  headerText: {
    fontFamily: 'Raleway',
    textAlign: 'center',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  placeholderText: {
    color: '#A3A3A3',
  },
  formField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  successDialogContainer: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  submittedText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  leaveReviewHeaderText: {
    fontFamily: 'Raleway',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  profileFieldHeader: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  subHeaderText: {
    fontFamily: 'Raleway',
    color: '#444',
    textAlign: 'center',
  },
  textFieldContainer: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  },
  successIcon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  selectField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  checkboxContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  backIcon: {
    height: theme.spacing(4),
    width: 'auto',
  },
  checkboxLabel: {},
  footerText: {
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontWeight: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  termsText: {
    textAlign: 'center',
    fontFamily: 'Raleway',
    fontWeight: 300,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  textCta: {
    color: buttonBlue,
    textDecoration: 'underline',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loginHeader: {
    color: '#444',
  },
  loginOauthHeader: {
    paddingTop: theme.spacing(1),
    color: '#444',
  },
  lineThrough: {
    position: 'relative',
    zIndex: 1,
    display: 'block',
    width: '100%',
    color: '#757575',
    textAlign: 'center',
    fontSize: '80%',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: '-1',
      marginTop: '-5px',
      marginLeft: '-20px',
      width: '40px',
      height: '10px',
      backgroundColor: '#fff',
      content: '""',
    },
    '&:after': {
      position: 'absolute',
      top: '49%',
      zIndex: '-2',
      display: 'block',
      width: '100%',
      borderBottom: '1px solid #ddd',
      content: '""',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  referralButton: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
  },
  referralSelectField: {
    textAlign: 'left',
  },
  referralFieldHeader: {
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  referralContainer: {
    marginBottom: theme.spacing(1),
  },
  collapsableField: {
    marginTop: theme.spacing(-0.5),
  },
  errorIndicator: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  fieldHint: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  createAccountSubHeader: {
    marginBottom: theme.spacing(3),
  },
  checkBoxErrorFormHelperText: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(3),
  },
  checkAboveFieldsErrorFormHelperText: {
    marginBottom: theme.spacing(4),
  },
});

export default withStyles(styles);
