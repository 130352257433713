import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';

const styles = {
  root: {
    alignItems: 'center',
    backgroundColor: StyleConstants.buttonBlue,
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    height: '50px',
    justifyContent: 'center',
    margin: '15px auto',
    width: '100%',
    borderRadius: '5px',
    textTransform: 'none',

    '&:focus': {
      backgroundColor: StyleConstants.buttonBlueFocus,
      outline: 0,
    },

    '&:hover': {
      backgroundColor: StyleConstants.buttonBlueFocus,
    },
  },
};

export default withStyles(styles);
