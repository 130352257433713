/**
 * Primary Button
 *
 * Point of Reference: Ryan Stenberg/Anupya
 * Purpose: Button used as the base for all call to actions or to direct users through our desired flow
 * Location: N/A
 * Interacts-with: N/A
 */

// When overwriting styling of the primary button, use !important on the css class styling to ensure that it works

import React from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'react-bootstrap'
import Button from '@material-ui/core/Button';

import withStyles from './GoogleButtonStyles';

const GoogleButton = ({
  children,
  classes,
  isFacebook,
  isGoogle,
  small,
  style,
  ...baseProps
}) => (
  <Button
    className={classes.root}
    // style={style}
    variant="contained"
    {...baseProps}
  >
    {children}
  </Button>
);

GoogleButton.propTypes = {
  children: PropTypes.node,
  isFacebook: PropTypes.bool,
  isGoogle: PropTypes.bool,
  small: PropTypes.bool,
  classes: PropTypes.object.isRequired, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
};

GoogleButton.defaultProps = {
  children: null,
  isFacebook: false,
  isGoogle: false,
  small: false,
  style: {},
};

export default withStyles(GoogleButton);
