import withStyles from '@material-ui/core/styles/withStyles';
import * as StyleConstants from 'shared/constants/styleConstants';

const styles = {
  root: {
    backgroundColor: StyleConstants.googleOrange,
    border: '0px solid',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    height: '50px',
    justifyContent: 'center',
    margin: '15px auto',
    width: '100%',
    borderRadius: '0px',
    textTransform: 'none',
    fontFamily: 'Raleway',
    fontWeight: 300,

    '&:focus': {
      backgroundColor: StyleConstants.googleOrangeHover,
      outline: 0,
    },

    '&:hover': {
      backgroundColor: StyleConstants.googleOrangeHover,
    },
  },
};

export default withStyles(styles);
